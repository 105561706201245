import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PlainLayoutComponent } from '../plain-layout/plain-layout.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    TranslateModule,
    MatMenuModule,
    TermsAndConditionsComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: PlainLayoutComponent,
        // if we want to display the header based on current user state, then uncomment the following line and comment the above line
        // component: user.isLoggedIn ? DefaultLayoutComponent : LoggedOutLayoutComponent,
        children: [
          {
            path: '',
            component: TermsAndConditionsComponent,
          },
        ],
      },
    ]),
  ],
})
export class TermsAndConditionsModule {}
